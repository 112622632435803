import { Model } from './model';
import { Money } from './money';
import { Plan } from './plan';
import moment from 'moment-timezone';

export class PromoCode extends Model {
    constructor(data) {
        super(data);

        _.assignIn(this, data);
        this.title = data.code;
        this.setPlans(data.plans);
        this.canBeDeleted = data.editable;
        this.editable = data.editable;
        this.expired = false;
    }

    setEditable(item) {
        this.canBeDeleted = item.isEditable;
    }

    static isValidNow(startAt, endAt) {
        return (
            (startAt == null && endAt == null) ||
            (moment().diff(moment(startAt)) > 0 && moment().diff(moment(endAt)) < 0)
        );
    }

    setPlans(items) {
        this.plans =
            items != null
                ? _.map(items, p => (_.isString(p) ? new Plan({ '@id': p }) : p instanceof Plan ? p : new Plan(p)))
                : [];
    }
}
