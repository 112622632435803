import { User } from './user';
import _ from 'lodash';
import { Money } from '@/models/money';
import { PERIODS, PERIODSFREE } from '@/models/plan';
import { Project } from '@/models/project';
import { Model } from '@/models/model';
import { MerchantPartner } from '@/models/merchantPartner';

export class MerchantPartnerProjectLink extends Model {
    constructor(dataRaw) {
        const data = dataRaw[0] ?? dataRaw;

        super(data);
        let extData = {};
        if (dataRaw[0]) {
            extData = _.cloneDeep(dataRaw);
            delete extData[0];
        }

        _.assignIn(this, {
            ...data,
            ...{
                project: new Project(data.project),
            },
            ...extData,
        });
        if (data.user) {
            this.user = new MerchantPartner(data.user);
        }
    }

    hasFilledAccount() {
        return true;
    }
}
