import axios from 'axios';
import { API, APIURL } from '../index';

export default _.defaults(
    {
        URL: APIURL,

        merchantsWithPayments() {
            return axios.get(`${this.URL}public/merchants_with_payments`);
        },

        authorsStatistics() {
            return axios.get(`${APIURL}public/landing.json`);
        },
    },
    API
);
