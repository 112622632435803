import { User, USER_ROLES } from '@/models/user';
import { Admin } from '@/models/admin';
import { Moderator } from '@/models/moderator';
import { Support } from '@/models/support';
import { Merchant } from '@/models/merchant';
import { Consumer } from '@/models/consumer';
import { Partner } from '@/models/partner';
import { MerchantPartner } from '@/models/merchantPartner';

export class UserFabric {
    static initUser(data) {
        if (!data.roles && !data['@type'] && !data['type']) return null;

        if (data && data.roles && data.roles.length) {
            if (data.roles.some(v => v === USER_ROLES.MERCHANT)) return new Merchant(data);
            if (data.roles.some(v => v === USER_ROLES.CONSUMER)) return new Consumer(data);
            if (data.roles.some(v => v === USER_ROLES.ADMIN)) return new Admin(data);
            if (data.roles.some(v => v === USER_ROLES.MODERATOR)) return new Moderator(data);
            if (data.roles.some(v => v === USER_ROLES.SUPPORT)) return new Support(data);
            if (data.roles.some(v => v === USER_ROLES.MERCHANT_PARTNER)) return new MerchantPartner(data);
            if (data.roles.some(v => v === USER_ROLES.PARTNER)) return new Partner(data);
        }

        switch (data['@type'] || data.type) {
            case 'Merchant':
                return new Merchant(data);
            case USER_ROLES.CONSUMER:
                return new Consumer(data);
            case USER_ROLES.MERCHANT_PARTNER:
                return new MerchantPartner(data);
            case USER_ROLES.ADMIN:
                return new Admin(data);
            case USER_ROLES.MODERATOR:
                return new Moderator(data);
            case USER_ROLES.SUPPORT:
                return new Support(data);
            case USER_ROLES.PARTNER:
                return new Partner(data);
        }

        return new User(data);
    }
}
