import AuthRequired from '@/utils/AuthRequired';
import { ROUTES_COMMON } from '@/routers';
/*
 * IF SOME ROUTES CHANCED
 * TELL IT TO BACKEND DEV
 */
export const routerDefaultParams = {
    linkActiveClass: 'active',
    mode: 'history',
};

export const routes = [
    {
        path: '/',
        beforeEnter: AuthRequired,
        name: 'index',
        redirect: '/dashboard',
    },
    {
        path: '/',
        beforeEnter: AuthRequired,
        component: () => import('@/views/app/index'),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/appMerchantPartner/Dashboard'),
            },
            {
                path: '/finance',
                name: 'finance',
                component: () => import('@/views/app/finance/ListPartners'),
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@/views/appMerchantPartner/Notifications'),
            },
            {
                path: '/finance/requisites/:id',
                props: true,
                name: 'requisites',
                component: () => import('@/views/app/finance/AddRequisites'),
            },
        ],
    },
    {
        path: '/catalog',
        beforeEnter: AuthRequired,
        component: () => import('@/views/app/index'),
        children: [
            {
                path: '/',
                name: 'catalog',
                component: () => import('@/views/appMerchantPartner/Catalog'),
            },
            {
                path: ':id',
                props: true,
                name: 'project_id',
                component: () => import('@/views/appMerchantPartner/CatalogItem'),
            },
        ],
    },
    {
        path: '/profile',
        beforeEnter: AuthRequired,
        component: () => import('@/views/app/index'),
        children: [
            {
                path: '/',
                name: 'profile',
                component: () => import('@/views/Profile'),
            },
        ],
    },

    ROUTES_COMMON.PARTNERS_AGREEMENT,
    ROUTES_COMMON.AD_ACCEPT,
    ROUTES_COMMON.AGENCY_CONTRACT,
    ROUTES_COMMON.LOGIN,
    ROUTES_COMMON.LOGIN_EMAIL,
    ROUTES_COMMON.LOGOUT,
    ROUTES_COMMON.PAYMENT_PROCESSING,
    ROUTES_COMMON.ERROR,
    ROUTES_COMMON['404'],
    ROUTES_COMMON.OTHER,
];

export const routerParams = _.assignIn({ routes }, routerDefaultParams);
