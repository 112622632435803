import axios from 'axios';
import { API, APIURL } from '../index';

export default _.defaults(
    {
        URL: APIURL + 'merchant_partner_project_links',

        getProjects(data) {
            return axios.get(`${this.URL}`, { params: { ...data } });
        },
        id(id) {
            return axios.get(`${this.URL}/${id}.jsonld`);
        },
        add(data) {
            return axios.post(`${this.URL}`, { ...data });
        },

        edit(data) {
            let id = data.id;
            delete data.id;

            return axios.put(`${this.URL}/${id}.jsonld`, { ...data });
        },

        merchantPartnerStatistics(data) {
            return axios.get(`${APIURL}merchants_partner/statistics`, { params: data });
        },

        notifications(data) {
            return axios.get(`${APIURL}merchant_partner_notifications`, { params: data });
        },
        qrPaymentPage(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${this.URL}/${id}/qr/payment_page`, {
                responseType: 'blob',
            });
        },
    },
    API
);
