import axios from 'axios';
import { STORAGE } from './storage';
import { USER_ROLES } from '@/models/user';

const STAGE = 'dev';

export const DOMAIN_PROD = 'paywall.pw';
export const DOMAIN_LIST = ['merchant', 'partner', 'pp', 'paywall'];
export const PORT = window.location.port !== '' ? ':' + window.location.port : '';
export const DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
export const ISPROD =
    window.location.hostname == DOMAIN_PROD ||
    DOMAIN_LIST.includes(window.location.hostname.replace('paywall.pw', '').split('/')[0].replace('.', ''));
export const ISFEATURE = window.location.hostname.includes('feature');
export const ISDEV = window.location.hostname.includes('dev.');
export const ISLOCAL = window.location.hostname.includes('paywall.vm');
export const ISLOCALHOST = window.location.hostname.includes('paywall.localhost');
export const ISCICD = window.location.hostname.includes('.stage.');
export const SERVER_NAME = ISLOCAL ? STAGE : window.location.hostname.split('.')[0];

const APIURL_PROD = `${window.location.protocol}//api.${DOMAIN_PROD}/`;
const APIURL_ISCICD = `${window.location.protocol}//${SERVER_NAME}.stage.api.${DOMAIN}/`;
const APIURL_STAGE = `${window.location.protocol}//${SERVER_NAME}.api.${DOMAIN}/`;
const APIURL_LOCAL = `https://${STAGE}.api.paywall.pw/`;
const APIURL_LOCALHOST = `http://api.paywall.localhost/`;

export const APIURL = ISPROD
    ? APIURL_PROD
    : ISCICD && !ISLOCAL
      ? APIURL_ISCICD
      : ISLOCALHOST && !ISLOCAL
        ? APIURL_LOCALHOST
        : ISLOCAL
          ? APIURL_LOCAL
          : APIURL_STAGE;

export const MERCHANT_URL = `//${ISPROD ? `merchant.${DOMAIN_PROD}` : ISCICD && !ISLOCAL ? `${SERVER_NAME}.stage.merchant.${DOMAIN}` : ISLOCALHOST && !ISLOCAL ? `merchant.${DOMAIN}` : ISLOCAL ? `merchant.${DOMAIN}${PORT}` : `${SERVER_NAME}.merchant.${DOMAIN}`}`;
export const PARTNER_URL = `//${ISPROD ? `partner.${DOMAIN_PROD}` : ISCICD && !ISLOCAL ? `${SERVER_NAME}.stage.partner.${DOMAIN}` : ISLOCALHOST && !ISLOCAL ? `partner.${DOMAIN}` : ISLOCAL ? `partner.${DOMAIN}${PORT}` : `${SERVER_NAME}.partner.${DOMAIN}`}`;
export const MERCHANT_PARTNER_URL = `//${ISPROD ? `pp.${DOMAIN_PROD}` : ISCICD && !ISLOCAL ? `${SERVER_NAME}.stage.pp.${DOMAIN}` : ISLOCALHOST && !ISLOCAL ? `pp.${DOMAIN}` : ISLOCAL ? `pp.${DOMAIN}${PORT}` : `${SERVER_NAME}.pp.${DOMAIN}`}`;

export function getSPARole() {
    if (window.location.href.includes('pp.')) return USER_ROLES.MERCHANT_PARTNER;
    if (window.location.href.includes('partner.')) return USER_ROLES.PARTNER;

    return USER_ROLES.MERCHANT;
}
export const API = {
    URL: APIURL,

    items(data = {}) {
        return axios.get(`${this.URL}`, { params: { ...data } });
    },
    id(id) {
        return axios.get(`${this.URL}/${id}.jsonld`, { cache: false });
    },
    add(data) {
        return axios.post(`${APIURL}plain_projects`, { ...data });
    },
    addNeuro(data) {
        return axios.post(`${APIURL}neuro_projects`, { ...data });
    },
    addAccounts(data) {
        return axios.post(`${this.URL}`, { ...data });
    },
    addPlans(data) {
        return axios.post(`${APIURL}plain_plans`, { ...data });
    },
    edit(data) {
        let id = data.id;
        delete data.id;

        return axios.put(`${APIURL}plain_plans/${id}.jsonld`, { ...data });
    },
    editProject(data) {
        let id = data.id;
        delete data.id;

        return axios.put(`${APIURL}projects/${id}.jsonld`, { ...data });
    },
    remove(data) {
        return axios.delete(`${this.URL}/${data.id}`);
    },
    activate(data) {
        let id = data.id;
        delete data.id;

        return axios.post(`${this.URL}/${id}/activate`, { params: data });
    },
    archive(data) {
        let id = data.id;
        delete data.id;

        return axios.post(`${this.URL}/${id}/archive`, { params: data });
    },
    shortList(data) {
        return axios.get(`${this.URL}/minimal_data`, { params: { ...data } });
    },
    canBeDeleted(data) {
        let id = data.id;
        delete data.id;

        return axios.get(`${this.URL}/${id}/is_can_be_deleted`);
    },
};

axios.interceptors.request.use(
    function (config) {
        if (STORAGE.hasToken()) {
            if (config && config.params && config.params.hashId) {
                //TODO
            } else {
                config.headers['Authorization'] = `Bearer ${STORAGE.getToken()}`;
            }
        }

        config.headers['Accept-Language'] = axios.interceptors.request.customLocale;

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export const ENV = {
    TG: {
        loginRU: ISPROD ? 'paywall_login_bot' : ISFEATURE ? 'PaywallFeatureLoginBot' : 'PaywallDevLoginBot',
        loginEN: ISPROD ? 'paywall_login_bot' : ISFEATURE ? 'PaywallFeatureLoginBot' : 'PaywallDevLoginBot',
        loginPartnerRU: ISPROD
            ? 'paywall_login_partner_bot'
            : ISFEATURE
              ? 'PaywallFeatureLoginPartnerBot'
              : 'PaywallDevLoginPartnerBot',
        loginPartnerEN: ISPROD
            ? 'paywall_login_partner_bot'
            : ISFEATURE
              ? 'PaywallFeatureLoginPartnerBot'
              : 'PaywallDevLoginPartnerBot',

        managerRU: ISPROD ? 'Paywall_manager_bot' : ISFEATURE ? 'PaywallFeatureManagerBot' : 'PaywallDevManagerBot',
        managerEN: ISPROD ? 'Paywall_manager_bot' : ISFEATURE ? 'PaywallFeatureManagerBot' : 'PaywallDevManagerBot',
        payRU: ISPROD ? 'Paywall_paybot' : ISFEATURE ? 'PaywallFeaturePayRuBot' : 'PaywallDevPayRuBot',
        payEN: ISPROD ? 'paywall_en_paybot' : ISFEATURE ? 'PaywallFeaturePayEnBot' : 'PaywallDevPayEnBot',
    },
    YM: 65592334,
    GA: 'UA-172613527-1',
};
