import api from '@/api/modules/merchantPartners';
import * as store from '../store';
import { MerchantPartnerProjectLink } from '@/models/merchantPartnerProjectLink';
import _ from 'lodash';
import { Money } from '@/models/money';
import { Project } from '@/models/project';
import { APIURL } from '@/api';
import moment from 'moment-timezone';

const state = {
    projects: [],
    projectsTotal: null,
    detail: null,
    statistics: null,
    notifications: [],
    notificationsIsLast: null,
};

const getters = _.defaults(
    {
        projects: state => state.projects,
        projectsTotal: state => state.projectsTotal,
        detail: state => state.detail,
        notifications: state => state.notifications,
        notificationsIsLast: state => state.notificationsIsLast,
        statistics: state => state.statistics,
    },
    store.getters
);

const actions = {
    async get({ state, commit }, data) {
        commit(store.START_REQUEST);
        try {
            let res = await api.getProjects(data);
            commit('projects', res.data['hydra:member']);
            state.projectsTotal = res.data['hydra:totalItems'];
            commit(store.PENDING, false);
            return state.projects;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async id({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.id(data.id);
            commit('detail', new MerchantPartnerProjectLink(res.data));
            commit(store.PENDING, false);
            return state.detail;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async add({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.add(data);
            // commit('detail', new MerchantPartnerProjectLink(res.data))
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async edit({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.edit(data);
            commit(store.PENDING, false);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async delete({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.remove(data);
            commit(store.PENDING, false);
            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async notifications({ state, commit }, data) {
        commit(store.START_REQUEST);

        if (parseInt(data.pageNumber) === 1) {
            commit('setNotifications', null);
        }
        try {
            let res = await api.notifications(data);
            commit(store.PENDING, false);
            commit('setNotifications', res.data);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async statistics({ state, commit }, data) {
        commit(store.START_REQUEST);

        if (data.start) {
            data.start = moment(data.start).format('YYYY-MM-DDT00:00:00');
        }
        if (data.end) {
            data.end = moment(data.end).format('YYYY-MM-DDT23:59:59');
        }

        try {
            let res = await api.merchantPartnerStatistics(data);
            commit('setStatistics', res.data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async qrPaymentPage({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.qrPaymentPage(data);
            commit(store.PENDING, false);
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(new Blob([res.data]));
            });
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
};

const mutations = _.defaults(
    {
        projects(state, i) {
            state.projects = _.map(i, p => new MerchantPartnerProjectLink(p));
        },
        detail(state, i) {
            state.detail = i;
        },

        setStatistics(state, items) {
            state.statistics = items;
        },
        setNotifications(state, items) {
            if (!items) {
                state.notificationsIsLast = null;
                state.notifications = [];
                return;
            }

            state.notificationsIsLast = items['isLast'];

            state.notifications = [
                ...state.notifications,
                ...items['items'].map(itm => {
                    itm.contentUrl = APIURL.slice(0, -1) + itm.contentUrl;
                    return itm;
                }),
            ];
        },
    },
    store.mutations
);

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
