import { User } from './user';
import _ from 'lodash';
import { Money } from '@/models/money';
import { PERIODS, PERIODSFREE } from '@/models/plan';
import { Account } from '@/models/account';

export class MerchantPartner extends User {
    constructor(data) {
        super(data);

        this.notificationReadAt = data?.notificationReadAt ?? null;
        this.hasProjects = data?.hasProjects ?? null;
        this.hasNotifications = data?.hasNotifications ?? null;

        this.fio = data.fio;
        this.emailPersonal = data.emailPersonal;
        this.phone = data.phone;
        this.country = data.country;
    }

    hasFilledAccount() {
        return this.fio && this.emailPersonal && this.phone && this.country ? true : false;
    }

    setAccounts(list) {
        if (list) this.accountLoaded = true;

        this.accounts = list ? _.map(list, b => (b instanceof Account ? b : new Account(b))) : [];
    }

    hasFilledPaymentAccounts() {
        let hasTinkoff = _.some(
            _.filter(this.accounts, f => f.isTinkoff()),
            a => {
                if (a.isActive()) return a;
            }
        );

        let hasOOO = _.some(
            _.filter(this.accounts, f => f.isOOO()),
            a => {
                if (a.isActive() && a.hasRequisite()) return a;
            }
        );

        let hasW2 = _.some(
            _.filter(this.accounts, f => f.isStripe()),
            a => {
                if (a.hasUsdtAddress()) return a;
            }
        );

        return hasTinkoff || hasOOO || hasW2;
    }

    hasActiveIndividualAccount() {
        return true;
    }
}
