<template lang="pug">
	b-modal#modalNeedToFillProfile(centered content-class="modal-withdraw-created mx-auto" body-class="p-0" hide-footer hide-header )
		.d-flex.flex-column
			.needToFill-img.mb-4
				img.mb-2.mx-auto(src="/assets/img/current/cookie-writing.png" alt="withdraw created")
			h3.text-center.mb-4 {{ $t('merchantPartners.modalNeedToFillProfile.title') }}
			h3.text-center.mb-4.text-grey {{ $t('merchantPartners.modalNeedToFillProfile.description') }}
			.btn.btn-primary.w-100.mt-auto(@click="goTo") {{$t('merchantPartners.modalNeedToFillProfile.btn')}}
</template>
<script>
export default {
    name: 'ModalNeedToFillProfile',
    methods: {
        onHide() {
            this.$bvModal.hide(`modalNeedToFillProfile`);
        },
        goTo() {
            this.$router.push({ name: 'profile' });
            this.onHide();
        },
    },
};
</script>
<style lang="scss" scoped>
.needToFill-img {
    width: 139px;
    height: 139px;
    border-radius: 100px;
    background: var(--Light-Primary, #eff7ff);
    overflow: hidden;
    margin: 0 auto;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        padding-top: 30px;
    }
}
</style>
<style lang="scss">
#modalNeedToFillProfile {
    pointer-events: none;
}
</style>
