export const SERVICE_STRING = {
    ru_ends: function (iNumber, aEndings) {
        var sEnding, i;

        iNumber = iNumber % 100;

        if (iNumber >= 11 && iNumber <= 19) {
            sEnding = aEndings[2];
        } else {
            i = iNumber % 10;
            switch (i) {
                case 1:
                    sEnding = aEndings[0];
                    break;
                case 2:
                case 3:
                case 4:
                    sEnding = aEndings[1];
                    break;
                default:
                    sEnding = aEndings[2];
            }
        }

        return sEnding;
    },
    hashCode: function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            var char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return String(hash).replace('-', '');
    },

    isValidURL: function (url) {
        //const urlPattern = new RegExp(/^(https?:\/\/)([\da-z.-]+)\.([a-z0-9.:]{2,})([/\w.-]*)*\/?$/i);
        const urlPattern = new RegExp(
            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-z0-9.-]+\.[a-z0-9:]{2,})(?:\/[\w.0-9:-]*)*(?:\?[^\s]*)?$/i
        );
        //const urlPattern = new RegExp(/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i);

        return urlPattern.test(url);
    },
    isValidEmail(value) {
        const EMAIL_REGEXP = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,14}$/;
        return EMAIL_REGEXP.test(value);
    },
    isValidPhone(value) {
        const REGEXP = /^\+[0-9]\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/;
        return REGEXP.test(value);
    },
    ucFirst: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
};
