import api from '@/api/modules/landing';
import * as store from '../store';

const state = _.defaults(
    {
        merchantsWithPayments: null,
        authorsStatistics: null,
    },
    store.state
);

const getters = _.defaults(
    {
        merchantsWithPayments: state => state.merchantsWithPayments,
        authorsStatistics: state => state.authorsStatistics,
    },
    store.getters
);

const actions = {
    async getMerchantsWithPayments({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.merchantsWithPayments();
            commit('setMerchantsWithPayments', res.data);
            commit(store.PENDING, false);
            return state.list;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async getAuthorsStatistics({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.authorsStatistics();
            commit('setAuthorsStatistics', res.data);
            commit(store.PENDING, false);
            return state.list;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
};

const mutations = _.defaults(
    {
        setMerchantsWithPayments(state, i) {
            state.merchantsWithPayments = i;
        },
        setAuthorsStatistics(state, i) {
            state.authorsStatistics = i;
        },
    },
    store.mutations
);

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
};
